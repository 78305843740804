<template>
  <v-container
    fluid
    :style="
      isAffiliateView ? 'padding-top: 0; padding-left: 0' : 'padding-top: 0'
    "
  >
    <v-layout
      column
      sheet
      :style="isAffiliateView ? 'padding-top: 0; padding-left: 0' : ''"
    >
      <v-layout row justify-space-between style="margin-right: 100px">
        <h1 v-if="!isAffiliateView" class="page-header">Market Rate Reviews</h1>
      </v-layout>
      <DataTable v-bind="tableProps" />
    </v-layout>
  </v-container>
</template>

<script>
import DataTable from '@/components/DataTable.vue'
import MarketFilterVue from '@/components/MarketFilter.vue'
import MarketRateReviewTableDetail from '@/components/MarketRateReviewTableDetail.vue'
import marketRateReviews from '@/services/marketRateReviews'
import { DateTime } from 'luxon'
import { EventBus } from '@/utils/event-bus'

const actionTypeMap = [
  { text: 'Created', value: 'created' },
  { text: 'Modified', value: 'modified' },
]

export default {
  components: {
    DataTable,
  },
  metaInfo() {
    return {
      title: 'Market Rate Reviews',
    }
  },
  props: {
    company: {
      type: Object,
      default: () => {},
    },
    isAffiliateView: {
      type: Boolean,
    },
  },
  data() {
    return {
      dialogType: '',
      filters: () => [],
      sorts: () => [],
      user: {},
      debounce: undefined,
      actionsDialog: false,
      itemsPerPage: 10,
      page: 1,
      isAdmin: true,
      tableProps: {
        enableColumnConfig: false,
        enableStatusFilterToggle: false,
        enableSavedViews: !this.isAffiliateView,
        tableId: 'market_rate_review_table_view',
        sort: this.sort,
        setSort: this.setSort,
        changePage: this.changePage,
        list: [],
        detail: MarketRateReviewTableDetail,
        detailKeyId: 'marketRateReviewId',
        isDetailed: false,
        shareFilters: this.receiveFilters,
        shareSorts: this.receiveSorts,
        initialFilters: [],
        columns: [
          {
            _t_id: '153c1040',
            prop: 'marketRateReviewId',
            text: 'ID',
            sort: true,
            filter: true,
            type: 'number',
            filterType: 'eq',
            defaultSort: true,
          },
          {
            _t_id: 'e0069b86',
            prop: 'marketRateReviewStatusTypeLabel',
            text: 'Status',
            sort: true,
            filter: true,
            type: 'text',
            predefined: [
              {
                text: 'Pending',
                controlType: 'default-repeat',
                refreshOnSelect: true,
                withValue: true,
                value: 'Pending',
              },
              {
                text: 'Approved',
                controlType: 'default-repeat',
                refreshOnSelect: true,
                withValue: true,
                value: 'Approved',
              },
              {
                text: 'Rejected',
                controlType: 'default-repeat',
                refreshOnSelect: true,
                withValue: true,
                value: 'Rejected',
              },
              {
                text: 'Closed',
                controlType: 'default-repeat',
                refreshOnSelect: true,
                withValue: true,
                value: 'Closed',
              },
            ],
            filterType: 'contains',
          },
          {
            _t_id: '6e5afe46',
            prop: 'company',
            text: 'Company',
            sort: true,
            filter: true,
            type: 'text',
            sortProp: 'company/name',
            filterProp: 'company/name',
            filterType: 'contains',
            defaultHidden: this.isAffiliateView,
          },
          {
            _t_id: 'ef5cc826',
            prop: 'companyMarket',
            text: 'Company Market',
            sort: true,
            filter: true,
            predefined: [
              {
                text: 'Markets',
                controlType: 'default-repeat',
                defaultOnSelection: true,
                refreshOnSelect: true,
                controls: [
                  {
                    id: '37e10zb1',
                    text: 'Markets',
                    filterType: 'contains',
                    component: MarketFilterVue,
                  },
                ],
              },
            ],
            type: 'text',
            filterType: 'contains',
          },
          {
            _t_id: 'e51aa52a',
            prop: 'companyMarket',
            filterType: 'eq',
            filterPlaceholder: true,
          },
          {
            _t_id: 'd6618d06',
            prop: 'actionType',
            text: 'Action Type',
            sort: true,
            filter: true,
            type: 'text',
            computedText: (item) => this.mapValueToText(actionTypeMap, item),
            filterType: 'contains',
          },
          {
            _t_id: '9ec258a2',
            prop: 'vehicleType/label',
            text: 'Vehicle Type',
            sort: true,
            filter: true,
            type: 'text',
            filterType: 'contains',
          },
          {
            _t_id: 'f52a32b8',
            prop: 'createdOn',
            text: 'Created Date',
            sort: true,
            filter: true,
            type: 'date',
            computedText: (item) =>
              item === null
                ? ''
                : DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
            filterType: 'eq',
          },
          {
            _t_id: 'a1f6d2f0',
            prop: 'reviewedOn',
            text: 'Decision Date',
            sort: true,
            filter: true,
            type: 'date',
            computedText: (item) =>
              item === null
                ? ''
                : DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
            filterType: 'eq',
          },
          {
            _t_id: '9960ec2d',
            prop: 'reviewedBy',
            text: 'Decision User',
            sort: true,
            filter: true,
            type: 'text',
            filterType: 'contains',
          },
        ],
        collection: 'MarketRateReviews',
        loading: true,
        total: 0,
        addFilter: this.addFilter,
        removeFilter: this.removeFilter,
      },
    }
  },
  mounted() {
    if (this.isAffiliateView) {
      const companyIdFilterObject = {
        column: {
          _t_id: 'b7b33b25-5aff-423a-9125-0cfd595ae5d1',
          prop: 'companyId',
          filterType: 'eq',
        },
        value: this.company.companyId,
        hideOnFilterBar: true,
      }
      this.tableProps.initialFilters.push(companyIdFilterObject)
    }
    EventBus.$on('market-rate-review-tv-update-row', (row, newStatus) =>
      this.updateRow(row, newStatus)
    )
  },
  methods: {
    receiveFilters(filters) {
      this.filters = filters
    },
    receiveSorts(sorts) {
      this.sorts = sorts
    },
    refresh(immediate) {
      if (this.debounce) {
        clearTimeout(this.debounce)
      }
      if (immediate) {
        this.getTable(true)
      } else {
        this.debounce = setTimeout(() => this.getTable(true), 500)
      }
    },
    addFilter() {
      this.currentPage = 1
      this.refresh()
    },
    removeFilter() {
      this.currentPage = 1
      this.refresh(true)
    },
    async getTable() {
      const sorts = this.sorts.asQueryParams()
      const filters = this.filters.asQueryParams()
      let additionalQueries = null
      if (this.isAffiliateView) {
        additionalQueries = 'isAffiliateDetailsView=true'
      }
      const params = {
        sorts,
        filters,
        additionalQueries,
        pageSize: this.itemsPerPage,
        page: this.currentPage,
      }
      this.tableProps.loading = true

      const tableResults = await marketRateReviews.getMarketRateReviews(params)

      const results = tableResults.data
      this.tableProps.perPage = this.itemsPerPage
      this.tableProps.currentPage = this.currentPage
      this.tableProps.list = results.resultList
      this.tableProps.total = results.count
      this.tableProps.loading = false
    },
    sort(sortItem) {
      this.sorts.add(sortItem)
      this.refresh(true)
    },
    changePage(pagination) {
      this.currentPage = pagination.page
      this.itemsPerPage = pagination.rowsPerPage
      this.refresh()
    },
    mapValueToText(map, item) {
      const match = map.find((status) => status.value === item)
      if (match) {
        return match.text
      }
      return item
    },
    updateRow(row, newStatus) {
      const indexToUpdate = this.tableProps.list.findIndex(
        (marketRateReview) =>
          marketRateReview.marketRateReviewId === row.marketRateReviewId
      )
      if (indexToUpdate >= 0) {
        const currentRow = {
          ...this.tableProps.list[indexToUpdate],
          marketRateReviewStatusTypeLabel: newStatus,
          reviewedBy: row.reviewedByName,
          reviewedOn: row.reviewedOn,
        }
        this.tableProps.list[indexToUpdate] = currentRow
      }
    },
  },
}
</script>

<template>
  <CRSelect
    :id="`${id}-market-filter-select-market-id`"
    v-model="marketSelections"
    :items="markets"
    chips
    flat
    hide-details
    item-text="marketName"
    item-value="marketName"
    :multiple="!disableMultiple"
    solo
    @input="setFilter"
  />
</template>

<script>
import markets from '@/services/markets'
import { deepClone } from '@/utils/deepClone'

export default {
  props: {
    disableMultiple: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Object,
      default: () => undefined,
    },
    control: {
      type: Object,
      default: () => undefined,
    },
    column: {
      type: Object,
      default: () => undefined,
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      markets: [],
      marketSelections: [],
      filter: {
        column: {
          _t_id: 'e51aa52a',
          prop: 'companyMarket',
          filterType: 'eq',
        },
        activeFilter: undefined,
        hideOnFilterBar: true,
      },
    }
  },
  async mounted() {
    const marketsData = await markets.tableView({
      pageSize: -1,
    })

    this.markets = marketsData?.data?.resultList || []
    this.markets.sort((a, b) => (a.marketName > b.marketName ? 0 : -1))

    const activeFilter = this.findFilter(this.filter)
    if (activeFilter) {
      this.activeFilter = activeFilter
      this.$nextTick(() => {
        const rebuiltSelections = activeFilter.value || ''
        this.marketSelections = rebuiltSelections.split(' ')
      })
    }
  },
  methods: {
    findFilter(filter) {
      return filter && this.filters.find(filter)
    },
    setFilter() {
      this.filters.remove(this.filter)
      if (this.marketSelections.length) {
        const grandParent = this.filters.createParent('and')
        this.grandParentFilter = grandParent
        this.addMarketLocationFilter()
      }
      this.$emit('make-dirty-request')
    },
    addMarketLocationFilter() {
      const newFilter = deepClone(this.filter)
      newFilter.value = this.marketSelections.join(' ')
      newFilter.stepParent = this.column._t_id
      this.filters.add(this.grandParentFilter, newFilter)
    },
  },
}
</script>

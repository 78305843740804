import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { Market } from '@/models/dto/Market'

const httpService: HttpService = new HttpService()

export default {
  tableView(params: TableViewParameters): Promise<AxiosResponse<TableViewResult<Market>>> {
    const { pageSize = 10, page = 1, sorts = null, filters = null } = params
    const host = baseUrl()
    let query = `page=${page}&pageSize=${pageSize}`

    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)

    return httpService.get(`https://${host}/tables/markets?${query}`) // Hey, just in case there's a git blame here, this is not a market rate but the actual markets.
  },
  byCompanyId(companyId: number) {
    const host = baseUrl()
    const url = `https://${host}/companies/${companyId}/markets`

    return httpService.get(url)
  },
  byId(marketId: number) {
    const host = baseUrl()
    const url = `https://${host}/v2/markets/${marketId}`

    return httpService.get(url)
  },
  update(params: { id: number, payload: Market}) {
    const host = baseUrl()
    const url = `https://${host}/v2/markets/${params.id}`

    return httpService.patch(url, params.payload)
  },
  create(params: { payload: Market}) {
    const host = baseUrl()
    const url = `https://${host}/v2/markets`

    return httpService.post(url, params.payload)
  },
  delete(marketId: number) {
    const host = baseUrl()
    const url = `https://${host}/v2/markets/${marketId}`

    return httpService.delete(url)
  }
}

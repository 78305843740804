<template>
  <v-container class="justify-center">
    <template v-if="loading">
      <v-progress-linear :indeterminate="true" height="1" />
    </template>
    <template v-else>
      <v-layout column>
        <v-flex>
          <v-layout row wrap>
            <v-flex class="has-border top-left" text-xs pl-2 xs1></v-flex>
            <v-flex class="has-border" text-xs pl-2 xs1>
              <h6>Transfer</h6>
            </v-flex>
            <v-flex class="has-border" text-xs pl-2 xs1>
              <h6>Dead Mile</h6>
            </v-flex>
            <v-flex class="has-border" text-xs pl-2 xs1>
              <h6>Live Mile</h6>
            </v-flex>
            <v-flex class="has-border" text-xs pl-2 xs1>
              <h6>Hourly</h6>
            </v-flex>
            <v-flex class="has-border" text-xs pl-2 xs1>
              <h6>Hourly Minimum</h6>
            </v-flex>
            <v-flex class="has-border top-right" text-xs pl-2 xs1>
              <h6>Daily</h6>
            </v-flex>
          </v-layout>
          <v-layout v-if="currentRates" row wrap>
            <v-flex class="has-border" text-xs pl-2 xs1>Current</v-flex>
            <v-flex class="has-border" text-xs pl-2 xs1>
              {{ currentRates.transferRate }}
            </v-flex>
            <v-flex class="has-border" text-xs pl-2 xs1>
              {{ currentRates.deadMileRate }}
            </v-flex>
            <v-flex class="has-border" text-xs pl-2 xs1>
              {{ currentRates.liveMileRate }}
            </v-flex>
            <v-flex class="has-border" text-xs pl-2 xs1>
              {{ currentRates.hourlyRate }}
            </v-flex>
            <v-flex class="has-border" text-xs pl-2 xs1>
              {{ currentRates.hourlyMinimum }}
            </v-flex>
            <v-flex class="has-border right" text-xs pl-2 xs1>
              {{ currentRates.dailyRate }}
            </v-flex>
          </v-layout>
          <v-layout v-if="newRates" row wrap>
            <v-flex class="has-border" text-xs pl-2 xs1>New</v-flex>
            <v-flex class="has-border" text-xs pl-2 xs1>
              {{ newRates.transferRate }} {{ percentChange.transferRate }}
            </v-flex>
            <v-flex class="has-border" text-xs pl-2 xs1>
              {{ newRates.deadMileRate }} {{ percentChange.deadMileRate }}
            </v-flex>
            <v-flex class="has-border" text-xs pl-2 xs1>
              {{ newRates.liveMileRate }} {{ percentChange.liveMileRate }}
            </v-flex>
            <v-flex class="has-border" text-xs pl-2 xs1>
              {{ newRates.hourlyRate }} {{ percentChange.hourlyRate }}
            </v-flex>
            <v-flex class="has-border" text-xs pl-2 xs1>
              {{ newRates.hourlyMinimum }} {{ percentChange.hourlyMinimum }}
            </v-flex>
            <v-flex class="has-border right" text-xs pl-2 xs1>
              {{ newRates.dailyRate }} {{ percentChange.dailyRate }}
            </v-flex>
          </v-layout>
          <v-layout v-if="charterUPRates" row wrap>
            <v-flex class="has-border bottom-left" text-xs pl-2 xs1>
              Market
            </v-flex>
            <v-flex class="has-border bottom" text-xs pl-2 xs1>
              {{ charterUPRates.transferRate }}
            </v-flex>
            <v-flex class="has-border bottom" text-xs pl-2 xs1>
              {{ charterUPRates.deadMileRate }}
            </v-flex>
            <v-flex class="has-border bottom" text-xs pl-2 xs1>
              {{ charterUPRates.liveMileRate }}
            </v-flex>
            <v-flex class="has-border bottom" text-xs pl-2 xs1>
              {{ charterUPRates.hourlyRate }}
            </v-flex>
            <v-flex class="has-border bottom" text-xs pl-2 xs1>
              {{ charterUPRates.hourlyMinimum }}
            </v-flex>
            <v-flex class="has-border bottom-right" text-xs pl-2 xs1>
              {{ charterUPRates.dailyRate }}
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <br />
      <v-layout>
        <v-flex xs9>
          <v-layout row wrap>
            <v-flex xs8>
              <CRTextArea
                :id="`${marketRateReviewId}-market-rate-review-created-reason`"
                v-model="createdReason"
                disabled
                label="Request Reason"
              />
            </v-flex>
            <v-flex v-if="isPending || isRejected" xs8>
              <label style="display: flex; justify-content: space-between" mb-1>
                <span style="font-size: 14px; align-self: flex-end">
                  Reject Reason
                </span>

                <span v-if="isPending" xs1>
                  <v-btn
                    :id="`${marketRateReviewId}-market-rate-review-detail-button-reject`"
                    :loading="loading"
                    color="error"
                    @click="rejectMarketRateReview"
                  >
                    Reject
                  </v-btn>
                  <v-btn
                    :id="`${marketRateReviewId}-market-rate-review-detail-button-approve`"
                    :loading="loading"
                    class="btn-primaryaction"
                    @click="approveMarketRateReview"
                  >
                    Approve
                  </v-btn>
                </span>
              </label>
              <CRTextArea
                :id="`${marketRateReviewId}-market-rate-review-reject-reason`"
                v-model="rejectReason"
                :disabled="isRejected"
              />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </template>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import marketRateReviews from '@/services/marketRateReviews'
import { EventBus } from '@/utils/event-bus'

export default {
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    props: {
      type: Object,
      default: () => ({}),
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      currentRates: null,
      newRates: null,
      percentChange: {},
      createdReason: null,
      rejectReason: null,
      loading: false,
    }
  },
  computed: {
    marketRateReviewId() {
      return this.row?.marketRateReviewId
    },
    isPending() {
      return this.row?.marketRateReviewStatusTypeLabel === 'Pending'
    },
    isRejected() {
      return this.row?.marketRateReviewStatusTypeLabel === 'Rejected'
    },
  },
  async mounted() {
    await this.getMarketRates()
  },
  methods: {
    ...mapActions({ showAlert: 'app/showAlert' }),
    async getMarketRates() {
      this.loading = true
      const marketRateReview = await marketRateReviews.getMarketRateReviewDetail(
        { id: this.marketRateReviewId }
      )
      this.loading = false

      this.currentRates = marketRateReview?.data?.currentRates
      this.newRates = marketRateReview?.data?.newRates
      this.charterUPRates = marketRateReview?.data?.charterUPRates
      this.createdReason = this.newRates?.marketRateReviewNotesCreated?.[0]?.note
      this.rejectReason = this.newRates?.marketRateReviewNotesReason?.[0]?.note
      if (this.currentRates && this.newRates) {
        for (let rateType in this.newRates) {
          if (this.newRates[rateType] - this.currentRates[rateType]) {
            let percentChange =
              100 *
              ((this.newRates[rateType] - this.currentRates[rateType]) /
                this.currentRates[rateType])

            const prefix = percentChange > 0 ? '+' : ''

            this.percentChange[rateType] = `(${prefix}${percentChange.toFixed(
              0
            )}%)`
          }
        }
      }
    },
    async approveMarketRateReview() {
      const payload = {
        marketRateReviewId: this.marketRateReviewId,
      }

      this.loading = true
      const response = await marketRateReviews.approveMarketRateReview(payload)

      if (response?.status === 200) {
        this.showAlert({
          type: 'success',
          message: `Market rate approved!`,
        })

        const updatedMarketRate = response?.data?.marketRateReview
        EventBus.$emit(
          'market-rate-review-tv-update-row',
          updatedMarketRate,
          'Accepted'
        )

        this.props.expanded = false
      } else {
        this.showAlert({
          type: 'error',
          message: 'Error approving market rate, please try again',
        })
        this.loading = false
      }
    },
    async rejectMarketRateReview() {
      const INTERNAL_NOTE_TYPE = 1

      const payload = {
        marketRateReviewId: this.marketRateReviewId,
        marketRateReviewNotesReason: [
          {
            note: this.rejectReason,
            noteType: INTERNAL_NOTE_TYPE,
          },
        ],
      }

      this.loading = true
      const response = await marketRateReviews.rejectMarketRateReview(payload)

      if (response?.status === 200) {
        this.showAlert({
          type: 'success',
          message: `Market rate rejected!`,
        })

        const updatedMarketRate = response?.data?.marketRateReview
        EventBus.$emit(
          'market-rate-review-tv-update-row',
          updatedMarketRate,
          'Rejected'
        )

        this.props.expanded = false
      } else {
        this.showAlert({
          type: 'error',
          message: 'Error rejecting market rate, please try again',
        })
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss">
.has-border {
  border-top: 1px solid gray;
  border-left: 1px solid gray;

  &.top-right {
    border-right: 1px solid gray;
    border-top-right-radius: 4px;
  }

  &.top-left {
    border-top-left-radius: 4px;
  }

  &.bottom-left {
    border-bottom: 1px solid gray;
    border-bottom-left-radius: 4px;
  }

  &.bottom-right {
    border-right: 1px solid gray;
    border-bottom: 1px solid gray;
    border-bottom-right-radius: 4px;
  }

  &.bottom {
    border-bottom: 1px solid gray;
  }

  &.right {
    border-right: 1px solid gray;
  }
}
</style>

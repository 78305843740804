import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { MarketRateReview, MarketRateReviewResult } from '@/models/dto/Market'

const httpService: HttpService = new HttpService()

export default {
  getMarketRateReviews(params: TableViewParameters): Promise<AxiosResponse<TableViewResult<MarketRateReview>>> {
    const {
      pageSize = 10,
      page = 1,
      sorts = null,
      filters = null,
      additionalQueries = null,
    } = params

    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    if (additionalQueries) {
      query += `&${additionalQueries}`
    }
    query = encodeURI(query)

    const host = baseUrl()
    const url = `https://${host}/marketRateReview?${query}`
    return httpService.get(url)
  },
  getMarketRateReviewDetail({ id }): Promise<AxiosResponse<MarketRateReviewResult>> {
    const host = baseUrl()
    const url = `https://${host}/marketRateReview/${id}/detail`
    return httpService.get(url)
  },
  approveMarketRateReview(payload: MarketRateReview): Promise<AxiosResponse<MarketRateReviewResult>> {
    const host = baseUrl()
    const url = `https://${host}/marketRateReview/approve`
    return httpService.post(url, payload)
  },
  rejectMarketRateReview(payload: MarketRateReview): Promise<AxiosResponse<MarketRateReviewResult>> {
    const host = baseUrl()
    const url = `https://${host}/marketRateReview/reject`
    return httpService.post(url, payload)
  },
}
